import React from 'react';
import Helmet from 'react-helmet';

import { BASE_URL } from 'config';
import { Layout } from 'containers';
import { Banner } from 'components';

const AccommodationList = () => (
  <Layout page="accommodation-list">
    <Helmet
      title="Accommodation List"
      meta={[
        {
          name: 'description', content: 'Haka Tours | Accommodation List',
        },
        { name: 'og:title', content: 'Haka Tours | Accommodation List' },
        {
          name: 'og:description', content: 'Haka Tours | Accommodation List',
        },
      ]}
      link={[{ rel: 'canonical', href: `${BASE_URL}/accommodation-list/` }]}
    />
    <Banner modifier="c-banner--tiny" backgroundImage="https://static-hakatours.imgix.net/south-island-snow-safari-haka-tours-04.jpg?auto=format">
      <h1 className="c-heading c-heading--h1">Accommodation List</h1>
    </Banner>

    <section className="l-accommodation-list">
      <div className="">
        <embed
          type="text/html"
          src="https://s3.ap-southeast-2.amazonaws.com/files.hakatours.com/Haka_Tours_Accommodation_list.pdf#navpanes=0&scrollbar=0"
          style={{ width: '100%', height: 'calc(100dvh)' }}
        />
      </div>
    </section>
  </Layout>
);

export default AccommodationList;
